<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp">
      <v-row no-gutters>
        <v-col cols="12" class="general-datatable-content">
          <v-data-table
            :headers="headers"
            :items="dataForm"
            :sort-by="['start_date']"
            :sort-desc="[false]"
            no-data-text="No Data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @pagination="updatePagination"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search">
                <v-col cols="12" md="12">
                  <div class="total-heading total-heading-left" style="visibility: hidden">
                    <h1>Holiday Management</h1>
                  </div>
                  <div class="filters-board">
                    <v-col cols="4">
                      <v-row no-gutters class="filter-group">
                        <v-col cols="12" md="4" class="modal-label">
                          <span>Global:</span>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-select
                            ref="siteGlobalSelect"
                            :items="listGlobals"
                            v-model="global"
                            item-text="name"
                            item-value="id"
                            :class="`${global ? 'custom-hidden-input' : ''}`"
                            class="custom-select__selections"
                            required
                            filled
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                            append-icon="fas fa-caret-down"
                            placeholder="Select Global"
                            @change="handleChangeGlobal"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="filter-group pt-2">
                        <v-col cols="12" md="4" class="modal-label">
                          <span>Regional:</span>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-select
                            ref="siteRegionalSelect"
                            :items="listRegionals"
                            v-model="regional"
                            item-text="name"
                            item-value="id"
                            :class="`${regional ? 'custom-hidden-input' : ''}`"
                            class="custom-select__selections"
                            required
                            filled
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                            append-icon="fas fa-caret-down"
                            placeholder="Select Regional"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row no-gutters class="filter-group">
                        <v-col cols="12" md="4" class="modal-label">
                          <span>Holiday in:</span>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-row no-gutters class="holiday-filter-year date-picker-controls">
                            <v-btn icon 
                              class="prev-button" 
                              @click="prevYear" 
                              :disabled="selectedYear <= NOW_DATE().format('YYYY')" 
                              :class="`${selectedYear <= NOW_DATE().format('YYYY') ? 'can-not-click' : ''}`"
                            >
                              <i class="fa-solid fa-caret-left" style="font-size: 34px;"></i>
                            </v-btn>
                            <v-select
                              :items="yearsList"
                              v-model="selectedYear"
                              filled
                              :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_menu_year custom_active_item' }"
                              append-icon="fas fa-caret-down"
                              class="custom-select-year"
                              @change="handleChangeYear"
                            ></v-select>
                            <v-btn icon 
                              class="next-button" 
                              @click="nextYear" 
                              :disabled="disabledNextButton(selectedYear, 'YYYY')" 
                              :class="`${disabledNextButton(selectedYear, 'YYYY') ? 'can-not-click' : ''}`"
                            >
                              <i class="fa-solid fa-caret-right" style="font-size: 34px;"></i>
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 + (currentPage - 1) * itemsPerPage }}
            </template>
            <template v-slot:[`item.start_date`]="{ item }">
              <td class="text-center">
                {{ item.start_date ? MOMENT(item.start_date, 'DD-MM-YYYY') : "−−"}}
              </td>
            </template>
            <template v-slot:[`item.end_date`]="{ item }">
              <td class="text-center">
                {{ item.end_date ? MOMENT(item.end_date, 'DD-MM-YYYY') : "−−"}}
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-content-center">
                <v-btn
                  :ripple="false"
                  icon
                  title="Edit Holiday"
                >
                  <img 
                    class="icon-action" 
                    src="@/assets/tables/asset_edit.svg" 
                    @click="openDialogEdit(item)" />
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  title="Delete Holiday"
                  @click="openConfirmDialog(item, deleteHoliday, 'Delete Holiday', 'You’re about to delete this holiday. This action cannot be undone. Still proceed?')"
                >
                  <img 
                    class="icon-action" 
                    src="@/assets/tables/asset_delete.svg" 
                  />
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="data-table-paging flex-footer">
            <button class="dialog-default-button btn_UserManagement data-table-add-size dialog-bg-grey-button switch-btn add-user-btn button-hover " @click="openDialogAddHoliday">
              Add New Holiday
            </button>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              :dark="true"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogAddHoliday"
        persistent
        content-class="custom-content-modals modal-input-not-mb custom-scroll-modals"
        max-width="786"
      >
        <v-card>
          <v-card-title>Add New Holiday</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="addForm"
              @submit.prevent="submitAdd"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters>
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Name</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="holidayName"
                      :rules="nameRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Name"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Start of Holiday</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuStartDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDateText"
                          :rules="startDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        :min="NOW_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedStartDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="startDateText = ''; startDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuStartDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>End of Holiday</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuEndDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDateText"
                          :rules="endDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        :min="NOW_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedEndDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="endDateText = ''; endDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuEndDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogAddHoliday"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEditHoliday"
        persistent
        content-class="custom-content-modals modal-input-not-mb custom-scroll-modals"
        max-width="786"
      >
        <v-card>
          <v-card-title>Edit Holiday</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="editForm"
              @submit.prevent="submitEdit"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters>
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Name</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="editHolidayName"
                      required
                      autocomplete="off"
                      :rules="nameRules"
                      placeholder="Enter Name"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>Start of Holiday</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuEditStartDate"
                      v-model="menuEditStartDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editStartDateText"
                          :rules="editStartDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editStartDate"
                        no-title
                        :min="NOW_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedEditStartDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="editStartDateText = ''; editStartDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuEditStartDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>End of Holiday</span>
                    <label class="symbol-required">*</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-menu
                      ref="menuEditEndDate"
                      v-model="menuEditEndDate"
                      :close-on-content-click="false"
                      :nudge-top="20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="custom_schedule_full_width"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editEndDateText"
                          :rules="editEndDateRules"
                          append-icon="fas fa-calendar-alt"
                          placeholder="Select Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editEndDate"
                        no-title
                        :min="NOW_DATE().format(DATE_PICKER_FORMAT)"
                        :dark="true"
                        class="customize_commissioned_date_picker custom_picker_actions tariff__date-picker"
                        @change="changedEditEndDate"
                      >
                        <v-btn class="actions__btn-default actions__btn-outline" text 
                          @click="editEndDateText = ''; editEndDate = null"
                        >
                          Clear
                        </v-btn>
                        <v-btn class="actions__btn-default actions__btn-background" text 
                          @click="menuEditEndDate = false"
                        >
                          Confirm
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogEdit"
                  >
                    CANCEL
                  </button>
                  <button
                    :disabled="!hasChanged"
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title>{{ dialogHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="dialogAction(holidayItem)"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { holidayService, multipleSitesService, } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "No", value: "no", align: 'center', sortable: false },
        { text: "Holiday Name", value: "name", align: 'center', sortable: true },
        { text: "Start Date", value: "start_date", align: 'center', sortable: true },
        { text: "End Date", value: "end_date", align: 'center', sortable: true },
        { text: "Action", value: "actions", sortable: false, align: 'center', filterable: false },
      ],
      dialogAddHoliday: false,
      dialogEditHoliday: false,
      confirmDialog: false,
      dialogAction: () => null,
      dialogHeading: "",
      dialogMessage: "",
      holidayItem: {},
      dataForm: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      currentPage: 1,
      listGlobals: [],
      global: "",
      listRegionals: [],
      regional: "",
      holidayName: "",
      menuStartDate: false,
      startDateText: "",
      startDate: null,
      menuEndDate: false,
      endDateText: "",
      endDate: null,
      editHolidayName: "",
      menuEditStartDate: false,
      editStartDateText: "",
      editStartDate: null,
      menuEditEndDate: false,
      editEndDateText: "",
      editEndDate: null,
      holidayInfo: null,
      yearsList: [],
      selectedYear: moment().format("YYYY"),
      timeOutFilter: null,
      nameRules: [
        (v) => !!v || "Holiday Name is required",
        (v) => (v && v.length <= 60) || "Holiday Name must be equal or less than 60 characters",
      ],
      selectMenuList: ["menuStartDate", "menuEndDate", "menuEditStartDate", "menuEditEndDate"],
    };
  },
  mounted() {
    this.yearsList = this.generateYearsList(15);
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('scroll', this.onScroll, true);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('scroll', this.onScroll, true);
  },
  async created() {
    await this.getListGlobals();
    if (this.listGlobals?.length) {
      // HARD CODE
      this.listGlobals = [this.listGlobals.find(item => item.name === "Thailand") || this.listGlobals[0]];
      this.global = this.listGlobals[0]?.id;
    }
    this.handleChangeGlobal();
  },
  computed: {
    startDateRules () {
      return [
        (v) => {
          if (!v) {
            return "Start Date is required";
          }
          return true;
        },
        (v) => {
          if (this.endDate) {
            const start = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            const end = moment(this.endDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "Start Date must be equal or earlier than End Date";
            }
          }
          return true;
        }
      ];
    },
    editStartDateRules () {
      return [
        (v) => {
          if (!v) {
            return "Start Date is required";
          }
          return true;
        },
        (v) => {
          if (this.editEndDate) {
            const start = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            const end = moment(this.editEndDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "Start Date must be equal or earlier than End Date";
            }
          }
          return true;
        }
      ];
    },
    endDateRules () {
      return [
        (v) => {
          if (!v) {
            return "End Date is required";
          }
          return true;
        },
        (v) => {
          if (this.startDate) {
            const start = moment(this.startDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            const end = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "End Date must be equal or later than Start Date";
            }
          }
          return true;
        }
      ];
    },
    editEndDateRules () {
      return [
        (v) => {
          if (!v) {
            return "End Date is required";
          }
          return true;
        },
        (v) => {
          if (this.editStartDate) {
            const start = moment(this.editStartDate, "YYYY-MM-DD").format(this.DATE_PICKER_FORMAT);
            const end = moment(v, "DD/MM/YYYY").format(this.DATE_PICKER_FORMAT);
            if (start > end) {
              return "End Date must be equal or later than Start Date";
            }
          }
          return true;
        }
      ];
    },
    hasChanged () {
      if (this.holidayInfo) {
        return (
          this.editHolidayName !== this.holidayInfo.name ||
          this.editStartDate !== this.holidayInfo.start_date ||
          this.editEndDate !== this.holidayInfo.end_date
        );
      }
      return false;
    },
  },
  methods: {
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.itemsPerPage = pagination.itemsPerPage;
    },
    generateYearsList(additionalYears) {
      const years = [];
      const startMoment = moment();
      const endMoment = moment().add(additionalYears, 'years');
      const currentMoment = startMoment.clone();
      while (currentMoment.isSameOrBefore(endMoment)) {
        years.push(currentMoment.format("YYYY"));
        currentMoment.add(1, 'years');
      }
      return years;
    },
    disabledNextButton(time) {
      const currentPlusTen = moment().add(10, 'years').format('YYYY');
      const formattedTime = moment(time).format('YYYY');
      return formattedTime >= currentPlusTen;
    },
    prevYear() {
      if(this.selectedYear) {
        this.selectedYear = moment(this.selectedYear).subtract(1, 'years').format('YYYY');
      }
      this.handleChangeYear();
    },
    nextYear() {
      if(this.selectedYear) {
        this.selectedYear = moment(this.selectedYear).add(1, 'years').format('YYYY');
      }
      this.handleChangeYear();
    },
    handleChangeYear() {
      if (this.timeOutFilter !== null) {
        clearTimeout(this.timeOutFilter);
      }
      this.timeOutFilter = setTimeout(async () => {
        this.selectedYear = moment(this.selectedYear).format('YYYY');
        this.getListHolidays();
      }, 500);
    },
    changedStartDate() {
      return this.startDateText = this.startDate ? moment(this.startDate).format("DD/MM/YYYY") : "";
    },
    changedEditStartDate() {
      return this.editStartDateText = this.editStartDate ? moment(this.editStartDate).format("DD/MM/YYYY") : "";
    },
    changedEndDate() {
      return this.endDateText = this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    },
    changedEditEndDate() {
      return this.editEndDateText = this.editEndDate ? moment(this.editEndDate).format("DD/MM/YYYY") : "";
    },
    async getListGlobals() {
      return await multipleSitesService
        .getListGlobals()
        .then((res) => {
          if (res?.data?.data) {
            const dataSiteGlobals = res.data.data;
            this.listGlobals = [...dataSiteGlobals];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    handleChangeGlobal() {
      this.listRegionals = [
        {
          "id": "all",
          "name": "ALL",
        }
      ];
      this.regional = "all";
      this.getListHolidays();
      // const selectedGlobal = this.listGlobals.find(global => global.id === global_id);
      // if (selectedGlobal) {
      //   this.listRegionals = [...this.listRegionals, ...selectedGlobal.regionals];
      // }
    },
    onMouseMove(event) {
      this.handleMouseMove(event, this);
    },
    onScroll() {
      this.handleScroll(this.selectMenuList, this);
    },
    getListHolidays() {
      if (this.global && this.selectedYear) {
        let data = { 
          global_id: this.global,
          year: this.selectedYear,
        };
        holidayService.getListHolidays(data).then((res) => {
          if(res?.data?.data) {
            let data = res.data.data;
            this.dataForm = [...data];
          }
        });
      }
    },
    deleteHoliday(item) {
      if (item?.id) {
        holidayService
          .deleteHoliday(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.apiMessage = "Holiday has been successfully deleted";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListHolidays();
          })
          .catch((err) => {
            let message = "Holiday cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    async submitAdd() {
      if (this.$refs.addForm.validate()) {
        const data = this.prepareData(
          this.global,
          this.holidayName,
          this.startDate,
          this.endDate,
        );
        let title = "Success";
        let message = "Holiday has been successfully added";
        await holidayService
          .storeHoliday(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getListHolidays();
            this.closeDialogAddHoliday();
            this.apiMessage = message;
            this.apiTitle = title;
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Holiday cannot be added";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertHoliday() {
      this.$refs.addForm.reset();
    },
    openDialogAddHoliday() {
      this.dialogAddHoliday = true;
    },
    closeDialogAddHoliday() {
      this.revertHoliday();
      this.startDate = null;
      this.endDate = null;
      this.dialogAddHoliday = false;
    },
    openConfirmDialog(item, action, heading, message) {
      this.holidayItem = Object.assign({}, this.holidayItem, item);
      this.dialogAction = action;
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.holidayItem = Object.assign({}, this.holidayItem);
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogHeading = "";
      this.dialogMessage = "";
    },
    closeMessage() {
      this.messageDialog = false;
    },
    prepareData(global_id, name, start_date, end_date) {
      const data = {
        global_id: global_id,
        name: name,
        start_date: start_date,
        end_date: end_date,
      };
      return data;
    },
    setHolidayInfo(data) {
      if (data) {
        this.editHolidayName = data.name || "";
        this.editStartDate = data.start_date || null;
        this.editEndDate = data.end_date || null;
        this.holidayInfo = data;
        this.changedEditStartDate();
        this.changedEditEndDate();
      } else {
        this.editHolidayName = "";
        this.editStartDate = null;
        this.editStartDateText = "";
        this.editEndDate = null;
        this.editEndDateText = "";
      }
    },
    openDialogEdit(item) {
      if (item?.id) {
        holidayService
          .getHoliday(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setHolidayInfo(res.data.data);
              this.dialogEditHoliday = true;
            }
          })
          .catch();
      }
    },
    closeDialogEdit() {
      this.setHolidayInfo(null);
      this.revertHolidayEdit();
      this.editStartDate = null;
      this.editEndDate = null;
      this.dialogEditHoliday = false;
    },
    submitEdit() {
      if (this.$refs.editForm.validate()) {
        const data = this.prepareData(
          this.global,
          this.editHolidayName,
          this.editStartDate,
          this.editEndDate,
        );
        holidayService
          .updateHoliday(this.holidayInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.apiMessage = "Holiday has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListHolidays();
            this.closeDialogEdit();
          })
          .catch((err) => {
            let message = "Holiday cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertHolidayEdit() {
      this.$refs.editForm.reset();
    },
  },
};
</script>
